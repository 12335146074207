<template>
  <div class="companyinfo-wrap" :class="{ 'companyinfo-pc-wrap': isPc }">
    <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
      <img src="../../assets/img/about.png" v-if="!isPc" alt="" />
      <img src="../../assets/img-pc/about-pc.png" v-else alt="" />
      <div class="text">
        <span class="chanese">关于喜尔美</span>
        <span class="english">About xiermei</span>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="company-wrap">
      <title-module :titleInfo="companyInfo" :isPc="isPc" /> 
      <div class="content" v-for="item in listData">
        <div class="img">
          <img :src="listData_pic" alt=""/>
        </div>
        <div class="text">
          <div v-html="item.content">
          </div>
        </div>
      </div>
    </div>

    <!-- 公司基地 -->
    <div class="com-base">
      <titleModule :titleInfo="comBase" :isPc="isPc" />
      <div class="content-wrap">
        <div class="content-item">
          <img src="../../assets/img/jidi-1.png" alt="" />
          <span>公司宿舍楼</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-2.png" alt="" />
          <span>公司鸟瞰图</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-3.png" alt="" />
          <span>仓储物流基地</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-4.png" alt="" />
          <span>喜尔美大厦景观图</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-5.png" alt="" />
          <span>喜尔美大厦景观图</span>
        </div>
        <div class="content-item">
          <img src="../../assets/img/jidi-6.png" alt="" />
          <span>喜尔美大厦景观图</span>
        </div>
      </div>
    </div>
    <!-- 公司荣誉  -->
    <div class="com-certificate">
      <titleModule :titleInfo="comCertificate" :isPc="isPc" />
      <div class="content-wrap">
        <img src="../../assets/img/certificate-1.png" alt="" />
        <img src="../../assets/img/certificate-2.png" alt="" />
        <img src="../../assets/img/certificate-3.png" alt="" />
        <img src="../../assets/img/certificate-4.png" alt="" />
      </div>
    </div>
    <!-- 专利信息  -->
    <div class="com-zl">
      <div class="content-wrap">
        <titleModule :titleInfo="comzl" :isPc="isPc" />
        <img class="img_zl" src="../../assets/img/zl1.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl2.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl3.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl4.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl5.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl6.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl7.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl8.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl9.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl10.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl11.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl12.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl13.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl14.jpg" alt="" />
        <img class="img_zl" src="../../assets/img/zl15.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { init } from "events";
import { getFeatureContent } from "@/api/index.js";
import titleModule from "../../components/title";
export default {
  name: "company-info",
  props: ["isPc"],
  components: {
    titleModule
  },

  data() {
    return {
      pageNumber:1,
      listData:[],
      listData_pic:'',
      companyInfo: {
        titleRed: "公司",
        titleWhite: "简介",
        remark: "COMPANY PROFILE"
      },
      comBase: {
        titleRed: "公司",
        titleWhite: "基地",
        remark: "COMPANY BASE"
      },
      comCertificate: {
        titleRed: "公司",
        titleWhite: "荣誉",
        remark: "COMPANY Certificate"
      },
      comzl: {
        titleRed: "产品",
        titleWhite: "专利",
        remark: "COMPANY Patent"
      }
    };
  },

  methods: {
  async getInfo(){
    const params = {
        pageNumber: 1,
        pageSize: 8,
        sort: "time",
        order: "asc",
        navigationId: "1305772076707221504"
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.listData = data  
        this.listData_pic = data[0].pictureList[0].fileUrl
      } catch (error) {
        console.log(error);
        this.$message.error("获取内容失败！");
      }
   }
  },

  created() {
    this.getInfo()
  } 
};
</script>

<style lang="less" scoped>
@import "./companyInfo.less";
companyinfo-wrap {
  background-color: #f7f7f7;
}
.company-wrap {
  padding: 48px;
  background-color: #fff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    .img {
      margin: 40px 0 20px;
      img {
        width: 1030px;
        height: 639px;
      }
    }
    .text {
      text-align: left;
      p {
        font-size: 30px;
        line-height: 60px;
        letter-spacing: 0px;
        color: #1d1b1b;
        text-indent: 2em;
      }
    }
  }
}
.com-base,
.com-certificate {
  background-color: #fff;
  padding: 48px 47px 48px 48px;
  margin-top: 20px;
  .content-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    .content-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.com-base {
  .content-wrap {
    img {
      width: 332px;
      margin: 40px 0 25px;
    }
    span {
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
  }
}
.com-certificate {
  .content-wrap {
    margin-top: 40px;
    img {
      width: 506px;
      height: 384px;
      margin-bottom: 20px;
    }
  }
}
</style>
